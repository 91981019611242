<div>
  @if (label()) {
    <myt-label [hasError]="control().invalid && control().touched" [control]="control()" [label]="label()" />
  }
  <mat-form-field class="myt-textarea w-full" [ngClass]="{ 'myt-textarea--border-elevation': borderElevation() }" appearance="outline">
    <textarea
      class="myt-textarea__input w-full"
      matInput
      [placeholder]="placeholder()"
      [formControl]="control()"
      [ngClass]="{ 'myt-textarea__input--resize-none': resizeNone }"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      [cdkAutosizeMinRows]="minRow()"
      [cdkAutosizeMaxRows]="maxRow()"
      [maxlength]="maxLength()"
      [rows]="rows()"
      (focus)="onFocus()"
      (blur)="blur.emit()"
      (keyup)="valueStore$.next($any($event.target).value)"
      [attr.aria-labelledby]="ariaLabelledBy()"
    ></textarea>
    @if (hint()) {
      <mat-hint
        ><span class="myt-input__hint">{{ hint() }}</span></mat-hint
      >
    }
    <mat-error>
      <span> {{ control().errors ? ($any(control()).errors | mytInputError: customValidationMessages()) : '' }} </span>
    </mat-error>
  </mat-form-field>
</div>
