import { TextFieldModule } from '@angular/cdk/text-field';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BaseInputDirective } from '../base-input/base-input.directive';
import { MytInputError } from '../input/input-error.pipe';
import { LabelComponent } from '../label/label.component';

@Component({
  selector: 'myt-textarea',
  standalone: true,
  imports: [NgClass, ReactiveFormsModule, TextFieldModule, MatFormFieldModule, LabelComponent, MytInputError, MatInputModule],
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TextareaComponent extends BaseInputDirective {
  readonly rows = input<number>(4);
  readonly minRow = input(undefined, {
    transform: (value: number | undefined): number => value || this.rows(),
  });
  readonly maxRow = input(undefined, {
    transform: (value: number | undefined): number => value || this.rows(),
  });
  readonly resizeNone = input(false);
  readonly borderElevation = input(false);
}
